<template>
  <ess-page-content title="TEMP User Management">
    <v-btn @click="createCoachAthleteAccounts(authUser.id)"
           :loading="$wait.is('athletesLoading')">
      Create Accounts
    </v-btn>
  </ess-page-content>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

export default {
  methods: {
    ...mapActions('athletes', ['createCoachAthleteAccounts']),

    ...mapWaitingActions('athletes', {
      createCoachAthleteAccounts: 'athletesLoading',
    }),
  },
};
</script>
